@mixin focus {
  &:focus {
    outline: none;
    box-shadow: $focus-box-shadow;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow: $focus-box-shadow;
  }
}
