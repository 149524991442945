@import "../../global.scss";

.ClientReport {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.Row {
  @media screen and (min-width: 64em) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: rem($gutter);
  }
}

.SectionHeading {
  font-family: $ff-heading;
  text-transform: uppercase;
  font-size: rem(36);
}
