@import "../../global.scss";

.Form {
  padding-top: rem(64);
  margin: 0 auto;
  width: calc(100% - #{rem($gutter * 2)});
  max-width: rem($fieldset-max-width);
}

.Submit {
  margin: rem($gutter * 2) 0;
}

.Input {
  margin-bottom: 0;
}

.Message {
  text-align: left;
  padding: 0;
  margin: rem($gutter) 0;
}
