@import "../../global.scss";

.Pill {
  border-radius: rem(30);
  font-size: (14 / 15) * 1em;
  font-weight: $fw-body-bold;
  padding: rem(5) rem(10) rem(6);
  margin: rem(-3) rem(-5);
  white-space: nowrap;
}
