@import "../../global.scss";

.Button {
  @include focus;

  --spinner-opacity: 1;

  border: rem(3) solid;
  background: transparent;
  color: $brand-accent-color;
  font-family: $ff-body;
  font-weight: $fw-body-bold;
  line-height: 1;
  text-transform: uppercase;
  transition: 250ms 100ms ease-in-out;
  transition-property: background-color, border-color, color, transform;
  position: relative;
  cursor: pointer;

  &:not(.loading) {
    &:hover,
    &:active {
      background-color: $brand-accent-color;
      border-color: $brand-accent-color;
      color: $brand-body-color-lightest;
    }

    &:active {
      transform: scale(0.98);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }
}

.Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1em;
}

.Children {
  margin-bottom: rem(1); // Optically balances the line height
}

.loading {
  cursor: progress;

  .Children {
    opacity: 0;
  }
}

.regular {
  font-size: rem(18);
  padding: rem(8) rem(24);
  min-height: rem(58);
}

.small {
  border-width: rem(2);
  font-size: rem(14);
  padding: rem(5) rem(16);
  min-height: rem(42);
}

.reversed:not(:hover) {
  color: $brand-body-color-lightest;
}
