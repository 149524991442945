@import "../../global.scss";

.TooltipWrapper {
  display: inline-block;
  cursor: pointer;
}

.TooltipPopper {
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid $brand-body-color-dark;
  background: $brand-body-color-light;
  padding: rem(8);
}

.ArrowDown {
  position: absolute;
  bottom: -7px;
  left: 50%;
  width: 8px;
  height: 8px;
  transform: translateX(-50%) rotateZ(135deg);
  background: $brand-body-color-light;
  border-top: 2px solid $brand-body-color-dark;
  border-right: 2px solid $brand-body-color-dark;
}
