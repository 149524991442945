@import "../../global.scss";

.Nav {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 10;
  background: $brand-body-color-dark;
  color: $brand-body-color-light;
  height: rem($navbar-height);
  width: 100%;
}

.Container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: calc(100% - #{rem($gutter) * 2});
}

.CompanyLogo {
  margin: rem($gutter);
}

.Text {
  margin-top: rem(6); // Vertically centers the text
  font-family: $ff-heading;
  font-size: rem(32);
  text-transform: uppercase;
  line-height: rem(28);
  margin-right: auto;
}

.Title {
  font-weight: $fw-heading-medium;
  margin-right: rem($gutter / 2);
}

.Subtitle {
  display: inline-block;
  font-weight: $fw-heading-regular;
  margin-right: rem($gutter);
}

.Refresh {
  border: none;
  background: none;
  color: $brand-body-color-light;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: rem(32);
  flex-shrink: 0;

  div {
    font-weight: $fw-heading-bold;
    text-align: right;

    span {
      font-weight: $fw-heading-regular;
      display: block;
    }
  }

  svg {
    height: rem(24);
    margin-left: rem(8);
  }
}

.Spinning {
  @keyframes spin {
    to {
      transform: rotate(-360deg);
    }
  }

  animation: spin 2s linear infinite;
}

.LogOut {
  flex-shrink: 0;
}
