@import "../../global.scss";

.Count {
  margin: 0 rem($gutter);
  padding: rem(4) rem(8);
  color: $brand-accent-color;
  background: $brand-body-color-mid;
  font-size: 14 / 18 * 1em;
  transform: skew($skew * -1);
  font-variant-numeric: tabular-nums;

  > span {
    display: block;
    transform: skew($skew);
  }
}

.CountReversed {
  color: $brand-body-color-lightest;
  background: rgba($brand-body-color-dark, 0.2);
}
