@import "../../global.scss";

.Table {
  tr:last-of-type {
    background-color: $brand-body-color-mid;
    td {
      font-weight: bold;
    }
  }
}
