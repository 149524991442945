@import "../../global.scss";

.FilterSidebar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: rem($navbar-height);
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: rem($sidebar-width);
  padding: rem($gutter);

  $scroller-track: transparent;
  $scroller-thumb: rgba($brand-body-color-dark, 0.25);
  $scroller-thumb-hover: rgba($brand-body-color-dark, 0.5);

  &::-webkit-scrollbar {
    width: rem(6);
    height: rem(6);
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(0deg, $scroller-thumb, $scroller-thumb);
    border-radius: rem(6);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(
      0deg,
      $scroller-thumb-hover,
      $scroller-thumb-hover
    );
  }
  &::-webkit-scrollbar-track {
    background: $scroller-track;
    border-radius: rem(6);
  }
}
