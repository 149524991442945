@import "../../global.scss";

.Table {
  border-collapse: collapse;
  font-size: rem(15);
  width: 100%;
  font-variant-numeric: tabular-nums;
}

.TableAlternatingRows {
  .Row:nth-child(2n) {
    .Cell {
      background-color: $brand-body-color-mid;
    }
  }
}

.TableWithTotal {
  tr:last-of-type {
    background-color: $brand-body-color-mid;
    td {
      font-weight: bold;
    }
  }
}

.Row {
  .Header & {
    background: $brand-body-color-mid;
  }

  .TableBody & {
    background: $brand-body-color-lightest;
    border-top: rem(2) solid $border-color;
  }

  .HeaderHidden & {
    border: 0;
  }
}

.Cell {
  box-sizing: border-box; // TOOD: Explore moving this to App.module.scss
  padding: rem(8) rem(12);
  height: rem(50);
  letter-spacing: -0.02em; // Tightens the content slightly to fit more in

  &:first-child {
    padding-left: rem($container-header-padding);
  }

  &:last-child {
    padding-right: rem($container-header-padding);
  }

  .HeaderHidden & {
    max-height: 0;
    height: 0;
    padding: 0;
  }
}

.CellAlignLeft {
  text-align: left;
}

.CellAlignRight {
  text-align: right;
}

.CellAlignCenter {
  text-align: center;
}

.CellEmpty {
  opacity: 0.33;
}
