@import "../../global.scss";

.Table {
  tr:hover {
    background: $brand-accent-highlight;
  }
}

.ChevronDown,
.ChevronUp {
  path {
    stroke: $brand-body-color-dark;
  }
}

.ChevronDown {
  transform: rotate(90deg);
}

.ChevronUp {
  transform: rotate(-90deg);
}

.ExpandToggle {
  background: none;
  border: 0;
  cursor: pointer;
}

.AssigneeCell,
.PlatformCell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  color: $brand-accent-color;
}

.TypeCell {
  padding-left: rem($gutter * 2);
  font-weight: 400;
}
