@import "../../global.scss";

.Container {
  display: flex;
  flex-direction: column;
  box-shadow: rem(0) rem(2) rem(8) #1616165c;
  margin: 0 0 rem($gutter);
  background-color: $brand-body-color-lightest;
}

.Header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $brand-accent-color;
  min-height: rem($container-header-height);
  padding: 0 rem($container-header-padding);
  color: $brand-body-color-lightest;
  width: 100%;
}

.Title {
  margin-left: rem($container-header-padding);
  margin: 0;
  font-size: rem(20);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  abbr {
    border: 0;
    text-decoration: none;
  }
}

.Children {
  overflow: auto;
  position: relative;
  flex: 1;
}
.ChildrenNotIdle {
  min-height: rem($container-children-min-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
