@import "../../global.scss";

$avatar-size-small: rem(30);
$avatar-size-medium: rem(30 * 2);
$avatar-size-large: rem(30 * 3);

.Avatar {
  --avatar-size: #{$avatar-size-medium};
  --font-size: calc(var(--avatar-size) * 0.8);
  width: var(--avatar-size);
  height: var(--avatar-size);
  line-height: var(--avatar-size);
  font-size: var(--font-size);
  overflow: hidden;
  display: inline-block;
  object-fit: cover;
  text-align: center;
  border-radius: 50%;
  background: $brand-body-color-light;
  color: $brand-body-color-dark;

  &.square {
    border-radius: 0;
  }

  &.large {
    --avatar-size: #{$avatar-size-large};
  }

  &.medium {
    --avatar-size: #{$avatar-size-medium};
  }

  &.small {
    --avatar-size: #{$avatar-size-small};
  }

  img {
    width: 100%;
  }
}
