@import "../../global.scss";

.LabelContainer {
  margin-bottom: rem($gutter / 2);
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: rem(8);
  align-items: center;
  font-size: rem(16);
  cursor: pointer;
  width: 100%;
  height: rem(24);
}

.Input {
  opacity: 0;
  width: rem($checkbox-width);
  height: rem($checkbox-height);
  cursor: inherit;
}

.Checkbox {
  position: absolute;
  width: rem(20);
  height: rem(20);
  border: 2px solid $brand-accent-color;
  //vertically aligns custom checkbox border ontop of input
  margin-top: rem(1);
  //horizontally aligns custom checkbox border ontop of input
  margin-left: rem(2);

  .Input:checked + & {
    background-color: $brand-accent-color;
  }

  .Input:focus + & {
    outline: none;
    box-shadow: $focus-box-shadow;
  }

  .Input:focus:not(:focus-visible) + & {
    box-shadow: none;
  }

  .Input:focus-visible + & {
    box-shadow: $focus-box-shadow;
  }
}

.CheckIcon {
  width: rem(14);
  height: rem(11);
  fill: none;
  stroke: $brand-body-color-light;
  stroke-width: rem(2);
  transition: transform 0.1s ease-in 25ms;
  transform: scale(0);
  transform-origin: center;
  padding: rem(4) rem(3); //centers svg tick in custom checkbox container

  .Input:checked + .Checkbox & {
    transform: scale(1);
  }
}

.Input:checked ~ .LabelText {
  font-weight: $fw-body-bold;
}
