@import "../../global";

.PieChartContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: rem(32);
}

.Chart {
  max-width: rem(128);
  margin-right: rem(64);
}

.Legend {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    margin: rem(12);
  }
}

.LabelColor {
  display: inline-block;
  width: rem(16);
  height: rem(16);
  border-radius: 50%;
  margin-right: rem(16);
  vertical-align: top;
}

.More {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: rem($gutter) rem($gutter * 2);
}

.Ellipsis {
  padding-top: rem(4);
}
