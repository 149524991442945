@import "../../global.scss";

.ChartContainer {
  padding: 0 rem($container-header-padding);
  display: flex;
  align-items: flex-end;
  gap: rem($gutter * 2);
  flex-direction: row;
  justify-content: space-between;
}

.Chart {
  // This compares against nothing if there is only one child so it
  // fills 100%, but if there is a second item it fills at a 5:3
  // ratio given the :last-child below
  flex: 5;

  &:last-child {
    flex: 3;
  }
}

.TotalPoints {
  display: block;
  margin: rem($gutter) 0;
  padding: 0 rem($container-header-padding);
  font-size: rem(18);
  font-weight: 700;
  text-align: right;
  color: $brand-body-color-dark;
  opacity: 0.75;
}
