@import "../../global.scss";

.Label {
  display: inline-block;
  margin: rem($gutter) 0 rem($gutter / 2);
  font-size: rem(14);
  font-weight: 700;
  letter-spacing: -0.025em; // Reduces the overall size of this label
  color: rgba($brand-body-color-dark, 0.75);
}

.ProgressChart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.ProgressChartSegment {
  box-sizing: border-box;
  position: relative;
  min-width: min-content;
  font-weight: $fw-body-bold;
  font-size: rem(14);
  padding: 0 rem(4);
  margin-left: -1px; // Aliasing fix
  line-height: rem(48);
  text-align: center;
  transform: skew($skew * -1);
}

.ProgressChartSegmentValue {
  position: relative;
  display: inline-block;
  z-index: 1;
  min-width: rem(16);
  transform: skew($skew);
}
