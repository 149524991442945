@import "../../global.scss";

.Message {
  box-sizing: border-box;
  font-weight: $fw-body-bold;
  padding: rem($gutter);
  font-size: rem(15);
  line-height: rem(18);
  text-align: center;
  opacity: 0.55; // Ensures a 4.5:1 ratio
  margin: 0 auto;
  width: 100%;
  max-width: 42em;
}

.MessageError {
  opacity: 1;
  color: $brand-accent-color;
}
