@import "../../global.scss";

.CycleDates {
  color: $brand-body-color-dark;
  font-weight: bold;
  font-size: rem(24);
  line-height: rem(29);
  margin-bottom: rem(9);

  > span {
    display: inline-block;
  }
}

.CycleDaysLeft {
  color: $brand-accent-color;
  font-weight: 400;
  font-size: rem(18);
  line-height: rem(24);
  margin-bottom: rem($gutter);
}
