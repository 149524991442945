@import "../../global.scss";

.Fieldset {
  display: flex;
  flex-direction: column;
  max-width: rem(320);
  margin: rem(15) 0 rem(30) 0;
}

.Label {
  font-size: rem(18);
  margin-bottom: rem(4);
  font-weight: $fw-body-bold;
}

.Input {
  @include focus;
  appearance: none;
  display: block;
  font-family: $ff-body;
  font-weight: $fw-body-regular;
  font-size: rem(16);
  padding: 0 rem(9);
  height: rem(40);
  border: rem(2) solid $border-color;
}

.Select {
  background-image: url('./select-arrow.svg');
  background-position: center right rem(8);
  background-repeat: no-repeat;
  padding-right: rem(30);
}
