@import "../../global.scss";

.Spinner {
  display: block;
  width: rem(20);

  &::before {
    animation: spinner 2s linear infinite;
    background: currentColor;
    display: block;
    content: '';
    position: relative;
    padding-top: 100%;
    opacity: var(--spinner-opacity, 0.25);

    @keyframes spinner {
      0%,
      #{0 + 5}% {
        transform: rotate(#{45 + 0}deg) scale(1);
      }

      #{25 - 5}%,
      #{25 + 5}% {
        transform: rotate(#{45 + (90 * 0.25)}deg) scale(0.75);
      }

      #{50 - 5}%,
      #{50 + 5}% {
        transform: rotate(#{45 + (90 * 0.5)}deg) scale(1);
      }

      #{75 - 5}%,
      #{75 + 5}% {
        transform: rotate(#{45 + (90 * 0.75)}deg) scale(0.75);
      }

      #{100 - 5}%,
      100% {
        transform: rotate(#{45 + 90}deg) scale(1);
      }
    }
  }
}

