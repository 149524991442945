@import "../global.scss";

@font-face {
  font-family: $font-heading;
  src: url("../assets/fonts/utd-cond/400.woff2") format("woff2");
  font-weight: $fw-heading-regular;
  font-style: normal;
}

@font-face {
  font-family: $font-heading;
  src: url("../assets/fonts/utd-cond/400i.woff2") format("woff2");
  font-weight: $fw-heading-regular;
  font-style: italic;
}

@font-face {
  font-family: $font-heading;
  src: url("../assets/fonts/utd-cond/500.woff2") format("woff2");
  font-weight: $fw-heading-medium;
  font-style: normal;
}

@font-face {
  font-family: $font-heading;
  src: url("../assets/fonts/utd-cond/600.woff2") format("woff2");
  font-weight: $fw-heading-bold;
  font-style: normal;
}

@font-face {
  font-family: $font-body;
  src: url("../assets/fonts/gtc/400.woff2") format("woff2"),
    url("../assets/fonts/gtc/400.woff") format("woff");
  font-weight: $fw-body-regular;
  font-style: normal;
}

@font-face {
  font-family: $font-body;
  src: url("../assets/fonts/gtc/400i.woff2") format("woff2"),
    url("../assets/fonts/gtc/400i.woff") format("woff");
  font-weight: $fw-body-regular;
  font-style: italic;
}

@font-face {
  font-family: $font-body;
  src: url("../assets/fonts/gtc/600.woff2") format("woff2"),
    url("../assets/fonts/gtc/600.woff2") format("woff2");
  font-weight: $fw-body-bold;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: $font-default-size / 16 * 1em;
  font-family: $ff-body;
  background-color: $brand-body-color-light;
  margin: 0;
}

a {
  @include link();
}

.PageWrapper {
  margin-top: rem($navbar-height);
}

:global(.sr-only) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
