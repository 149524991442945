@import "../../global.scss";

.MainContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: rem($gutter);
  margin-left: rem($sidebar-width);
  overflow: hidden;

  @media print {
    margin-left: rem($gutter);
  }
}
