@import "../../global.scss";

.FilterFacet {
  margin: 0 0 rem($gutter) 0;
  border: 0;
  padding: 0;
}

.FilterFacetLegend {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: rem($gutter / 2);
  font-size: rem(18);
  font-weight: 700;
}
