@import "../../global.scss";

.Actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-right: rem($gutter / -2);

  button {
    background: none;
    border: 0;
    padding: rem($gutter / 2);
    cursor: pointer;

    &:disabled {
      opacity: 0.6;
    }
  }
}

.IconChevronRight {
  display: block;
  margin-left: rem(2);
}

.IconChevronLeft {
  display: block;
  margin-right: rem(2);
  transform: rotate(180deg);
}

.PTOScrollable {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
