@import "../../global.scss";

.ChartContainer {
  padding: 0 rem($container-header-padding);
  display: flex;
  align-items: flex-end;
  gap: rem($gutter * 2);
  flex-direction: row;

  > * {
    flex: 2;
    &:last-child {
      flex: 4;
    }
  }
}

.TotalPoints {
  display: block;
  text-align: right;
  margin: rem($container-header-padding);
  font-size: rem(18);
  font-weight: 700;
  color: rgba($brand-body-color-dark, 0.75);
}
